import ParticlesBackground from './ParticlesBackground';
import '../styles/Hero.css';

const Hero = () => {
  
  const pcTitle = {
    fontFamily: 'SF-Pro-Display-Thin, helvetica',
    fontSize: '3vw',
    background: 'linear-gradient(to bottom, rgb(255, 246, 161) 10%, rgb(201, 181, 0) 100%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
    margin: '0',
  };

  const welcomeText = {
    color: 'rgb(211, 211, 211)',
    fontFamily: 'SF-Pro-Regaular, helvetica',
    fontSize: '1.3vw',
    textAlign: 'center',
    lineHeight: '1.5vw',
  };

  const availableText = {
    color: 'rgb(255,255,255)',
    marginTop: '1.5vw',
    marginBottom: '0.5vw',
    fontFamily: 'SF-Pro-Regaular, helvetica',
    fontSize: '1.3vw',
    textAlign: 'center',
  };

  return (

    <div className={"container-style"}>

        <ParticlesBackground id="tsparticles" />

        <div className="HeroContent">
            
            <img src="latestscreenshot.png" alt="Logo" className="iPhoneImage" />

            <div className="content">

                <p style={pcTitle}>PocketCipher</p>
                
                <p style={welcomeText}>
                    PocketCipher is an iOS application that can be used to encrypt or
                  decrpyt popular encryption methods and ciphers right from your iOS device.
                </p>

                <p style={availableText}>Availaible on</p>

                <img src='appstore.png' alt='appstoreimage' className='appStoreImage' />

            </div>

        </div>
    </div>
  );
};

export default Hero;