import '../styles/Contact.css';

function About() {

  const contactTitleStyles = {
    fontFamily: 'SF-Pro-Display-Ultralight, sans-serif',
    fontSize: '40px',
    marginTop: '65px',
    marginBottom: '45px',
    color: 'black'
};

  return (
    <div className="contact-section">

      <h2 style={contactTitleStyles}>Contact Information</h2>

      <div className="contact-entries">

        <div className="contact-card">
        <img src="stephen-pic.jpeg" className="contact-picture" alt="contactimage" />
          <h2>Stephen Sottosanti</h2>
          <h3>Software Engineer</h3>
          <p>ssottosanti@joystiq.gg</p>
          <a href='https://www.linkedin.com/in/stephen-sottosanti'>
            <img src="LinkedInLogo.png" alt="li-logo" className="linkedin-logo" />
          </a>
        </div>

        <div className="contact-card">
        <img src="connor-pic.jpeg" className="contact-picture" alt="contactimage" />
          <h2>Connor Sottosanti</h2>
          <h3>Software Engineer</h3>
          <p>csottosanti@joystiq.gg</p>
          <a href='https://www.linkedin.com/in/connor-sottosanti-3725201b1'>
            <img src="LinkedInLogo.png" alt="li-logo" className="linkedin-logo" />
          </a>
        </div>

      </div>

      <h3 className='general-inq-statement'>General inquiries can be sent to hello@joystiq.gg</h3>

  

    </div>
  );
}

export default About;