import './App.css';
import './global.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomeContent from './components/HomeContent';
import Footer from './components/Footer';
import TermsOfService from './components/TermsOfService';
import PrivacyStatement from './components/PrivacyPolicy'

/*
TD

*/

function App() {
  return (
    <Router>

    <div className="App">

      <Header />

      <Routes>
        <Route path="/" element={<HomeContent />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-statement" element={<PrivacyStatement />} />
      </Routes>

      <Footer />

    </div>

  </Router>
  );
}

export default App;
