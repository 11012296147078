import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const ParticlesBackground = ({ id, style }) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      autoPlay: true,
      background: {
        color: {
          value: ""
        },
        image: "",
        position: "",
        repeat: "",
        size: "",
        opacity: 1
      },
      backgroundMask: {
        composite: "destination-out",
        cover: {
          color: {
            value: "#000"
          },
          opacity: 1
        },
        enable: false
      },
      clear: true,
      defaultThemes: {},
      delay: 0,
      fullScreen: {
        enable: false,
        zIndex: 0
      },
      detectRetina: true,
      duration: 0,
      fpsLimit: 60,
      interactivity: {
        detectsOn: "window",
        events: {
          onClick: {
            enable: false,
            mode: {}
          },
          onDiv: {
            selectors: {},
            enable: false,
            mode: {},
            type: "circle"
          },
          onHover: {
            enable: false,
            mode: {},
            parallax: {
              enable: false,
              force: 2,
              smooth: 10
            }
          },
          resize: {
            delay: 0.5,
            enable: true
          }
        },
        modes: {}
      },
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            area: 1000
          }
        },
        color: {
          value: "rgb(255, 215, 0)" // Gold color for particles
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000"
          },
          polygon: {
            nb_sides: 5
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: 0,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0,
            sync: false
          }
        },
        size: {
          value: 3, // Reduced size for connection points
          random: true,
          anim: {
            enable: false,
            speed: 20,
            size_min: 0.1,
            sync: false
          }
        },
        links: {
          enable: true,
          distance: 180, // Increased distance between links
          color: "#ffd700", // Gold color for links
          opacity: 0.6,
          width: 2
        },
        move: {
          enable: true,
          speed: 2, // Slightly increased speed
          direction: "none",
          random: false,
          straight: false,
          outModes: {
            default: "out"
          },
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      pauseOnBlur: true,
      pauseOnOutsideViewport: true,
      themes: [],
      zLayers: 100,
      motion: {
        disable: false,
        reduce: {
          factor: 4,
          value: true
        }
      }
    }),
    []
  );

  return (
    <Particles
      id={id}
      options={options}
      init={initParticlesEngine}
      loaded={particlesLoaded}
      style={style}
    />
  );
};

export default ParticlesBackground;
