import '../styles/HomeContent.css';
import Contact from './Contact';
import Hero from './Hero';

function HomeContent() {
  return (

    <div className='HomeAboutContact-container'>

      <Hero />


      <Contact />

    </div>
    
  );
}

export default HomeContent;