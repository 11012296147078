import React from 'react';
import '../styles/Header.css'
import { Link } from 'react-router-dom';

function Header() {

  const scrollToHome = () => {
    // Calculate the target scroll position (e.g., 90vh from the top)
    const targetPosition = 0;

    // Use the scrollTo method to scroll smoothly to the target position
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth', // This enables smooth scrolling
    });
  };


  const scrollToAbout = () => {
    // Calculate the target scroll position (e.g., 90vh from the top)
    const targetPosition = window.innerHeight;

    // Use the scrollTo method to scroll smoothly to the target position
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth', // This enables smooth scrolling
    });
  };

  const scrollUp = () => {
    // Calculate the target scroll position as a negative value from the current scroll position
    const currentScrollPosition = window.scrollY;
    const targetPosition = currentScrollPosition - window.innerHeight * 5;
    
  
    // Use the scrollTo method to scroll smoothly to the target position
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth', // This enables smooth scrolling
    });
  };


  return (
    <header>

      <nav className="header-content">

        <img src="KeyLogoTransparent.png" alt="Logo" className="pc-logo" />

        <div className="left-buttons">
            <Link to="/" className='route-links' onClick={scrollToHome}>Home</Link>
            <Link to="/" className='route-links' onClick={scrollToAbout}>Contact</Link>
            <Link to="/terms-of-service" className='route-links' onClick={scrollUp}>Terms of Service</Link>
            <Link to="/privacy-statement" className='route-links' onClick={scrollUp}>Privacy Policy</Link>
        </div>

      </nav>

      <div className="dividing-line"></div>

    </header>
  );
};

export default Header;
